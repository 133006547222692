import { qs, qsa, fetchJSON } from "../utils/lib";
import _ from "lodash";
import Store from './store'

export default class CartSync {
  constructor(reset) {
    console.log("initter cart_sync")
    this.cart = JSON.parse(localStorage.getItem("lc_cart"));
    if ( reset ) {
      console.log("RESET")
      this.reset()
    } else {

      console.log("SYNC")
      this.sync()
    }
  }

  reset() {
    const empty = []
    localStorage.setItem('lc_cart', JSON.stringify(empty));
    Store.set("cart_items", [])
  }
    async sync() {
        console.log("cart")
        const AUTH_TOKEN = document.querySelector("meta[name=csrf-token]").getAttribute("content");
        const url = "/en/sync";
        const post = {
          cart: this.cart
        }

        const response = await fetchJSON(url, {
          method: "POST",
          body: JSON.stringify(post),
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "xmlhttprequest" /* XMLHttpRequest is ok too, it's case insensitive */,
          },
        })
    }

}

